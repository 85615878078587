<template>
  <div class="mainObjElement">
    <!-- 主记录附加字段名称 -->
    <div
      class="mainObjTitle"
      :style="
        cellDataData.schemefieldName === 'adjustment'
          ? 'margin-right: 15px;'
          : ''
      "
    >
      <span style="margin-right:14px">{{ cellDataData.name }}</span>
      <span>{{currencyVal}}</span>
    </div>
    <!-- 附加字段帮助文本 -->
    <div class="tips" v-show="cellDataData.schemefieldName === 'adjustment'">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('c27')"
        placement="top-start"
      >
        <svg aria-hidden="true" style="width: 12px; height: 12px">
          <use href="#icon-question"></use>
        </svg>
      </el-tooltip>
    </div>
    <!-- 不同字段类型展示不同样式 -->
    <div
      v-if="
        cellDataData.schemefieldName === 'totalcustomerdiscountamount' ||
        cellDataData.schemefieldName === 'totalcustomer' ||
        cellDataData.schemefieldName === 'tax' ||
        cellDataData.schemefieldName === 'discountamount' ||
        cellDataData.schemefieldName === 'discount' ||
        cellDataData.schemefieldName === 'zhekou' ||
        cellDataData.schemefieldName === 'taxamount'
      "
      class="cellInput"
    >
      <el-input
        v-model="cellDataData.defaultValue"
        :readonly="true"
        style="width: 260px"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-edit"
          @click="showCard(cellDataData.schemefieldName)"
        ></i>
      </el-input>
      <el-card
        v-show="
          tipMainShow.zhekouFlg &&
          cellDataData.schemefieldName !== 'tax' &&
          cellDataData.schemefieldName !== 'taxamount'
        "
        class="cellCard"
        id="discountCard"
      >
        <div class="titleTax">{{ $t("c26") }}</div>
        <div v-show="cellDataData.schemefieldName === 'tax'" class="titleTax">
          {{ $t("label.choose.is.tax") }}
        </div>
        <div class="discountCardInput">
          <el-radio-group v-model="radioData.radio">
            <el-radio style="margin: 10px 0px" label="1"
              ><span style="margin-right: 20px"
                >{{ $t("label.percent") }}
              </span>
              <el-input-number
                size="mini"
                style="width: 70px"
                v-show="radioData.radio === '1'"
                :min="0"
                :max="100"
                :controls="false"
                v-model.number="radioData.percentageValue"
              ></el-input-number>
              <span
                v-show="radioData.radio === '1'"
                style="color: #303133; margin-left: 5px; font-size: 14px"
                >%</span
              ></el-radio
            >
            <el-radio label="2" style="margin: 10px 0px"
              ><span style="margin-right: 10px">{{
                $t("label.diedirect.sale")
              }}</span>
              <el-input-number
                size="mini"
                style="width: 70px"
                type="number"
                v-show="radioData.radio === '2'"
                :min="min"
                :max="max"
                :controls="false"
                :precision="2"
                v-model.number="radioData.cutValue"
              >
              </el-input-number>
            </el-radio>
          </el-radio-group>
        </div>

        <div class="btBox">
          <el-button
            type="primary"
            size="mini"
            @click="discoundSave(cellDataData.schemefieldName)"
            >{{ $t("label.emailtocloudcc.button.complete") }}</el-button
          >
          <el-button size="mini" @click="cencel">{{
            $t("label.tabpage.cancel")
          }}</el-button>
        </div>
      </el-card>
      <!-- 税 -->
      <el-card
        v-show="
          tipMainShow.taxFlg &&
          (cellDataData.schemefieldName === 'tax' ||
            cellDataData.schemefieldName === 'taxamount')
        "
        class="cellCard"
        id="discountCard"
      >
        <div class="titleTax">{{ $t("label.choose.is.tax") }}</div>
        <div class="taxCheckBox">
          <!-- key值不能是引用类型值 -->
          <div v-for="tax in taxCheckList" :key="tax.id" class="taxList">
            <el-checkbox v-model="tax.checked"
              ><div class="taxName">{{ tax.name }}</div></el-checkbox
            >
            <el-input
              size="mini"
              style="width: 70px"
              v-model="tax.ratio"
              :disabled="true"
            />
            <span style="margin-left: 5px">%</span>
          </div>
        </div>

        <div class="btBox">
          <el-button
            type="primary"
            size="mini"
            @click="discoundSave(cellDataData.schemefieldName)"
            >{{ $t("label.emailtocloudcc.button.complete") }}</el-button
          >
          <el-button size="mini" @click="cencel">{{
            $t("label.tabpage.cancel")
          }}</el-button>
        </div>
      </el-card>
    </div>
    <!-- 调整 -->
    <el-input-number
      v-model.number="cellDataData.defaultValue"
      v-else-if="cellDataData.schemefieldName === 'adjustment'"
      :precision="2"
      :controls="false"
      @change="discoundSave(cellDataData.schemefieldName)"
      style="width: 260px"
    >
    </el-input-number>
    <!-- 其他数据 -->
    <el-input
      v-model="cellDataData.defaultValue"
      v-else
      style="width: 260px"
      :disabled="true"
    >
    </el-input>
  </div>
</template>

<script>
import { ListTax } from "./api";

export default {
  data() {
    return {
      radioData: {
        // 回显折扣方式
        radio: this.radioData && this.radioData.radio ? this.radioData.radio : "1",
        // 折扣百分比回显
        percentageValue: this.radioData && this.radioData.percentageValue ? this.radioData.percentageValue : "0",
        // 折扣减价回显
        cutValue: this.radioData && this.radioData.cutValue ? this.radioData.cutValue : "0",
      },
      taxCheckList: [],
      cellDataData: this.cellData,
    };
  },
  props: {
    // 当前父对象币种
    currencyVal: {
      type: String,
      default: "",
    },
    tipMainShow: {
      type: Object,
      default: () => {},
    },
    cellData: {
      type: Object,
      default: () => {},
    },
    /**
     * 数字最小值
     */
    min: {
      type: Number,
      default: 0 - Math.pow(10, 18) + 1,
    },
    /**
     * 数字最大值
     */
    max: {
      type: Number,
      default: Math.pow(10, 18) - 1,
    },
    /**
     * 编辑折扣数据回显
     */
    radioDataP: {
      type: Object,
      default: () => {},
    },
    /**
     * 编辑税数据回显
     */
    taxListP: {
      type: Object,
      default: () => {},
    },
  },
  //方法集合
  methods: {
    cencel() {
      this.$emit("tipMainShowMe", true, 1);
    },
    /**
     * 折扣/税/调整字段值变化时触发
     */
    discoundSave(type) {
      let data = {
        type: type, //类型
        radioData: this.radioData, // 折扣
        taxCheckList: this.taxCheckList, //选择税种
        adjustment: this.cellDataData.defaultValue, //调整
      };
      this.$emit("mainObjCount", data);
      this.$emit("tipMainShowMe", true, 1);
    },
    showCard(type) {
      if (type === "tax" || type === "taxamount") {
        if (this.taxCheckList.length == 0) {
          ListTax({ id: "" }).then((res) => {
            if (res.result) {
              res.data.forEach((item) => {
                item.checked = false;
              });
              this.taxCheckList = res.data;
              this.$emit("tipMainShowMe", true, '' , this.taxCheckList);
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
        } else {
          this.$emit("tipMainShowMe", true);
        }
      } else {
        this.$emit("tipMainShowMe", false);
      }
    },
  },
  created() {
    this.$emit("mainObjCount", this.taxListP);
  },
  watch: {
    /**
     * 监听数据变化,实时强制更新页面
     */
    cellDataData: {
      deep: true,
      immediate: true,
      handler: function () {
        this.$forceUpdate();
      },
    },
    /*
    编辑时折扣数据回显
    */
    radioDataP: {
      handler(newVal) {
        if (Object.keys(newVal).length > 0) {
          if(newVal.cutValue){
            newVal.cutValue = newVal.cutValue.toString().trim();
          }
          this.$set(this.radioData, "radio", newVal.radio);
          this.$set(this.radioData, "percentageValue", newVal.percentageValue);
          this.$set(this.radioData, "cutValue", newVal.cutValue);
        }
      },
      deep: true,
      immediate: true,
    },
    /*
    编辑时税数据回显
    */
    taxListP: {
      handler(newVal) {
        if (newVal?.taxCheckList[0]?.name != "") {
          this.taxCheckList = newVal.taxCheckList;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .el-input__prefix {
  color: #606266;
  left: 0px;
  transition: all 0.3s;
  top: 5px;
}
.taxCheckBox {
  max-height: 200px;
  overflow: auto;
  .taxList {
    height: 35px;
    .taxName {
      width: 70px;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 13px;
      font-size: 13px;
      white-space: nowrap;
      margin-left: 5px;
    }
  }
}
.mainObjElement {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 10px;
  .mainObjTitle {
    height: 16px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    margin-right: 10px;
  }
  .tips {
    width: 20px;
  }
  .el-input__inner {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    width: 264px;
    height: 40px;
  }
  .cellInput {
    position: relative;
    line-height: 30px;
  }
  .cellCard {
    position: absolute;
    top: 32px;
    z-index: 999;
    width: 100%;
    .titleTax {
      margin: 10px 0px;
      font-weight: bold;
    }
    .discountCardInput {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
      span {
        display: inline-block;
        width: 85px;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 14px;
        white-space: nowrap;
      }
    }
    .btBox {
      margin-top: 20px;
    }
  }
  ::v-deep .el-input--prefix .el-input__inner {
    padding-left: 35px !important;
  }
  ::v-deep .el-input__prefix {
    margin-left: 5px;
  }
}
</style>
